
function Projects(props: any) {
  return (
    <div id="page-projects" className="page">
      <div id="projects-container">
        <div><h1>Projects</h1></div>
        <div id="data-projects">
          {
            props.dataProjects.map((project: any) =>
              <div key={project.title} className="panel project-container" data-emoji={project?.emoji}>
                <h1>{project.title}</h1>
                <p>{project.description}</p>
                <div className="features">
                  {project.features.map((feature: string) => <div key={`${project.title}-${feature}`}>{feature}</div>)}
                </div>
                {
                  (project.url || project.repo) ?
                    <div className="project-resources">
                      {project.url ? <button onClick={() => { window.open(project.url, "_blank") }}>Site</button> : null}
                      {project.repo ? <button onClick={() => { window.open(project.repo, "_blank") }}>Repo</button> : null}
                    </div> : null
                }
                <div className="last-updated">Updated on {new Date(project.lastUpdated).toLocaleDateString()}</div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Projects;