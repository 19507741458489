import "./App.css";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Outlet, useLocation, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import SiteData from "./res/site_data.json";
import ResumePdf from "./res/Ryan_Clark_Resume_Public.pdf";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="about" element={<About dataAbout={SiteData.dataAbout} />} />
          <Route path="projects" element={<Projects dataProjects={SiteData.dataProjects} />} />
          <Route path="resume" element={<Resume />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </Router>
  );
}

function Layout() {
  const path = useLocation().pathname.slice(1);

  return (
    <div id="container">
      <nav>
        <Link to="/"><button id="nav-home" data-active={path === "" ? "" : undefined}>Home</button></Link>
        <Link to="about"><button id="nav-about" data-active={path === "about" ? "" : undefined}>About</button></Link>
        <Link to="projects"><button id="nav-projects" data-active={path === "projects" ? "" : undefined}>Projects</button></Link>
      </nav>

      <div id="main-section">
        <Outlet />
      </div>

      <footer>
        <div>Copyright &copy; {new Date().getFullYear()} Ryan Clark All Rights Reserved.</div>
        <div id="last-updated">Last Updated: {SiteData.lastUpdated}</div>
      </footer>

    </div>
  )
}

function Resume() {
  useEffect(() => { window.location = ResumePdf }, []);
  return (
    <div className="resume-redirect">
      <div className="spinner" />
      <button onClick={() => { window.location = ResumePdf }}>Click here if not redirected within 3 seconds.</button>
    </div>
  );
}

export default App;
