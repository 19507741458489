function About(props: any) {
  let p = 0;
  return (
    <div id="page-about" className="page">
      <div id="about-container">
        <div><h1>About Me</h1></div>
        <div>
          <div id="data-about">
            {
              props.dataAbout.map((data: string) => <p key={p++}>{data}</p>)
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;