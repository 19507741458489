import { useState } from "react";

function Home() {

  const [dot, setDot] = useState("1");

  const changeDot = (n: string) => {
    setDot(n);
    document.body.setAttribute("color", n);
  }

  const dots = ["1", "2", "3"].map(n =>
    <div key={`dot-${n}`} className={`dot dot-${n}`} data-active={dot === n ? "" : undefined} onClick={() => changeDot(n)} ></div>
  );

  return (
    <div id="page-home" className="page">
      <div id="home-container">
        <div id="img-me">
          {dots}
        </div>

        <div id="home-content">
          <div>
            <h1>Ryan Clark</h1>
            <p>Full stack developer, artist, musician and much more!</p>
          </div>

          <div className="external">
            External:
            <button title="GitHub" onClick={() => window.open("https://github.com/qualiarc", "_blank")}></button>
            <button title="LinkedIn" onClick={() => window.open("https://www.linkedin.com/in/ryan-lyman-clark/", "_blank")}></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;